import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class WhoAreWe extends React.Component {
	render() {
		const siteTitle = 'Who Are We?'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Who Are We?</h3>
								<p>
									WSC Group is an established financial services and advice
									practice with offices in Sydney South (Head Office) Sydney
									CBD, Brisbane, Gold Coast, Melbourne and Newcastle. We also
									meet clients in serviced offices in Perth, Canberra & Adelaide
									and can complete work for clients anywhere in Australia.
								</p>
								<p>
									Our business is people focused because we believe our approach
									to clients’ financial needs should be as unique as they are.
									Through understanding how our clients conduct their business,
									we can offer straightforward advice and guidance.
								</p>
								<p>
									At WSC Group, the skills, knowledge and expertise of our team
									have been developed through practical and continuous
									professional development. Therefore, you can be assured that
									our advice will be prepared by a qualified and experienced
									professional. We work with you to understand your business
									challenges and opportunities to provide you with the best
									possible financial advice in any situation.
								</p>
								<p>
									One of WSC Group’s special focuses is a property investment,
									including advice on portfolios, optimal structures for holding
									property and managing the taxation aspects of holding and
									selling property. We assist property investors with the
									preparation of their yearly tax returns and tax variations
									(1515’s) and also prepare Annual Accounts (P&L and Balance
									Sheet) for trusts, superannuation funds and corporate entities
									that hold property assets.
								</p>
								<p>
									We invite you to learn more about the WSC Group and the
									services we offer by contacting us at{' '}
									<a href="mailto:info@wscgroup.com.au">info@wscgroup.com.au</a>{' '}
									to set up an appointment in one of our many meeting locations
									around the country to discuss how we can assist you with your
									vision for the future.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default WhoAreWe

export const WhoAreWePageQuery = graphql`
	query WhoAreWePageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
